import { PayloadAction } from '@reduxjs/toolkit';
import { FailurePayload, SuccessPayload } from '../../effects';
import { put, takeEvery } from 'redux-saga/effects';
import { showErrorNotification, showSuccessNotification } from '../../ui/notifications';
import { loadUsersInOrganizationFailure, updateCrmPermissionFailure, updateCrmPermissionSuccess } from './actions';
import { isEmpty } from 'lodash';

export function* loadFailure({ payload: { request, error } }: PayloadAction<FailurePayload>) {
  yield put(
    showErrorNotification({
      message: !isEmpty(error) ? error : 'Failed to load CRM permissions',
      label: 'Retry',
      action: request,
    })
  );
}

export function* updateFailure({ payload: { request, error } }: PayloadAction<FailurePayload>) {
  const parsedError = (() => {
    try {
      const parsed = JSON.parse(error);
      return typeof parsed === 'object' && 'message' in parsed ? parsed : new Error('Unknown error');
    } catch (e) {
      return new Error(error);
    }
  })();

  yield put(
    showErrorNotification({
      message:
        typeof parsedError === 'object' && 'message' in parsedError
          ? `${parsedError.message}. Contact a developer for help.`
          : 'Failed to update CRM permission. Contact a developer for help.',
      label: 'Retry',
      action: request,
    })
  );
}

export function* updateSuccess({ payload: { response } }: PayloadAction<SuccessPayload>) {
  yield put(showSuccessNotification({ message: 'Updated CRM permission' }));
}

export function* effects() {
  yield takeEvery(loadUsersInOrganizationFailure, loadFailure);
  yield takeEvery(updateCrmPermissionFailure, updateFailure);
  yield takeEvery(updateCrmPermissionSuccess, updateSuccess);
}
